import React, { useState } from 'react'
const BASEURL = process.env.REACT_APP_BASE_URL;
let token = localStorage.getItem("auth-token") || "";
const useUpdateUserDetails = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<any>();

    const updateUser = async (id:string, body:string) => {
        try {
          setLoading(true);
         
          const response = await fetch(
            `${BASEURL}/v1/user/update/${id}`,
            {
              method:"PUT",
              body:body,
              headers: {
                "Content-Type": "application/json",
                // "x-user-token": token
                "x-user-token": `${JSON.parse(token)}`
              },
            }
          );
    
          const result = await response.json();
          console.log("result", result?.data);
            return result;
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      }
  return {updateUser, loading, error}
}

export default useUpdateUserDetails