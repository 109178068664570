import React from 'react'
import CircleLoader from "react-spinners/CircleLoader";
const CircleLoderItem = ({loading}:{loading:boolean}) => {
  return (
    <div className='border-transperent '>
        <CircleLoader
        loading={loading}
        color={"#4CC6DE"}
        size={100}
        aria-label="Loading Spinner"
        data-testid="loader"
        // width={"full"}
      /> 
    </div>
  )
}

export default CircleLoderItem