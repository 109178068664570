import {  useEffect, useState } from "react";
const BASEURL = process.env.REACT_APP_BASE_URL;
const useUserDetails = ({id}:{id:string}) => {
    // console.log("id", id)
  let token = localStorage.getItem("auth-token") || "";
  const [userDetails, setUserDetails] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  // const [propertyDetails, setPropertyDetails] = useState<any>({})
  // useCallback(()=>{

  // },[])
  

  useEffect(() => {
    let didCancel = false;
    setError(null);

    (async () => {
      try {
        setLoading(true);
       
        const response = await fetch(
          `${BASEURL}/v1/user/info/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const result = await response.json();        
        setUserDetails(result?.data);
        
        if (!didCancel) {
      
          didCancel =true
          }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      didCancel = true;
    };
  }, [id]);


 

  return {  userDetails,  loading, error };
};

export default useUserDetails ;
